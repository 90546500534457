"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserUploadAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserUploadAvatarMutation = client_1.gql `
	mutation UserUploadAvatarMutation($file: Upload!) {
		user {
			uploadAvatar(file: $file) {
				id
				avatar
				thumbnail: avatar(width: 5, height: 5)
			}
		}
	}
`;
