"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserUpdatePasswordMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserUpdatePasswordMutation = client_1.gql `
	mutation UserUpdatePasswordMutation($password: String!, $currentPassword: String) {
		user {
			updatePassword(password: $password, currentPassword: $currentPassword)
		}
	}
`;
