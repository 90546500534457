"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserUpdateInformationMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserUpdateInformationMutation = client_1.gql `
	mutation UserUpdateInformationMutation($input: MevrisUserInput!) {
		user {
			update(input: $input) {
				id
				username
				name
				customData
				email
				emailVerified
				phoneNumber
				phoneNumberVerified
				firstName
				lastName
				roles
				avatar
			}
		}
	}
`;
