"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirmwareCreateMutation = void 0;
const client_1 = require("@apollo/client");
exports.FirmwareCreateMutation = client_1.gql `
	mutation FirmwareCreateMutation($input: FirmwareInput!) {
		firmware {
			create(input: $input) {
				id
				code
				name
				description
				url
				latest
				schemaId
			}
		}
	}
`;
