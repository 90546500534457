"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityDeleteMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityDeleteMutation = client_1.gql `
	mutation EntityDeleteMutation($id: ID!) {
		entity {
			uninstall(id: $id)
		}
	}
`;
