"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirmwareDeployMutation = void 0;
const client_1 = require("@apollo/client");
exports.FirmwareDeployMutation = client_1.gql `
	mutation FirmwareDeployMutation($id: ID!) {
		firmware {
			deploy(id: $id) {
				id
				code
				name
				description
				url
				latest
				schemaId
			}
		}
	}
`;
