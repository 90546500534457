"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySetAttributesMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntitySetAttributesMutation = client_1.gql `
	mutation EntitySetAttributesMutation($id: ID!, $attributes: JSONObject!) {
		entity {
			setAttributes(id: $id, attributes: $attributes)
		}
	}
`;
