"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandUploadAvatarMutation = void 0;
const client_1 = require("@apollo/client");
exports.BrandUploadAvatarMutation = client_1.gql `
	mutation BrandUploadAvatarMutation($file: Upload!, $brandId: ID!) {
		brand {
			uploadAvatar(file: $file, id: $brandId) {
				id
				avatar
				thumbnail: avatar(width: 5, height: 5)
			}
		}
	}
`;
