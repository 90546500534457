"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirmwareSetLatestMutation = void 0;
const client_1 = require("@apollo/client");
exports.FirmwareSetLatestMutation = client_1.gql `
	mutation FirmwareSetLatestMutation($id: ID!) {
		firmware {
			setLatest(id: $id) {
				id
				code
				name
				description
				url
				latest
				schemaId
			}
		}
	}
`;
