"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaFirmwaresQuery = void 0;
const client_1 = require("@apollo/client");
exports.SchemaFirmwaresQuery = client_1.gql `
	query SchemaFirmwaresQuery(
		$id: ID!
		$orderBy: [OrderBy!]
		$filter: FirmwareFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$limit: Int
		$offset: Int
	) {
		schema(id: $id) {
			firmwares(
				orderBy: $orderBy
				filter: $filter
				after: $after
				before: $before
				first: $first
				last: $last
				limit: $limit
				offset: $offset
			) {
				edges {
					cursor
					node {
						id
						code
						name
						description
						url
						latest
						schemaId
						canSetToLatest
					}
				}

				pageInfo {
					hasNextPage
					hasPreviousPage
					startCursor
					endCursor
				}

				totalCount
			}
		}
	}
`;
