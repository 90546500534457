"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaDeleteMutation = void 0;
const client_1 = require("@apollo/client");
exports.SchemaDeleteMutation = client_1.gql `
	mutation SchemaDeleteMutation($id: ID!) {
		schema {
			delete(id: $id) {
				id
			}
		}
	}
`;
