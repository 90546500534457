"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirmwareQuery = void 0;
const client_1 = require("@apollo/client");
exports.FirmwareQuery = client_1.gql `
	query FirmwareQuery($id: ID!) {
		firmware(id: $id) {
			id
			code
			name
			description
			url
			latest
			schemaId
		}
	}
`;
