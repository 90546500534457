"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInfoQuery = void 0;
const client_1 = require("@apollo/client");
exports.UserInfoQuery = client_1.gql `
	query UserInfoQuery($avatarSize: Int) {
		me {
			id
			username
			name
			customData
			email
			emailVerified
			phoneNumber
			phoneNumberVerified
			firstName
			lastName
			roles

			avatar(width: $avatarSize, height: $avatarSize)
			thumbnail: avatar(width: 5, height: 5)
		}
	}
`;
