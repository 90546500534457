"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRequestAccountDeletionMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserRequestAccountDeletionMutation = client_1.gql `
	mutation UserRequestAccountDeletionMutation {
		user {
			requestAccountDeletion
		}
	}
`;
