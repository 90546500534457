"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityActiveIncidentsQuery = void 0;
const client_1 = require("@apollo/client");
// update query
exports.EntityActiveIncidentsQuery = client_1.gql `
	query EntityActiveIncidentsQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId

			parts {
				id
				key
				name
				description
				group
			}

			incidents(filter: { status: { in: ["ACTIVE", "UNKNOWN"] } }, first: 25) {
				edges {
					cursor
					node {
						id
						status
						startTime
						endTime
						fault {
							id
							name
							severity
						}
						part {
							id
							name
						}
					}
				}
				totalCount
				pageInfo {
					startCursor
					endCursor
					hasNextPage
					hasPreviousPage
				}
			}
		}
	}
`;
