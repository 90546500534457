"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSendEmailVerificationCodeMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserSendEmailVerificationCodeMutation = client_1.gql `
	mutation UserSendEmailVerificationCodeMutation($email: String!) {
		user {
			sendEmailVerificationCode(email: $email)
		}
	}
`;
