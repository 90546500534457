"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySetStateMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntitySetStateMutation = client_1.gql `
	mutation EntitySetStateMutation($id: ID!, $state: JSONObject!) {
		entity {
			setState(id: $id, state: $state)
		}
	}
`;
