"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaSetBulkAttributesOfAllEntitiesMutation = void 0;
const client_1 = require("@apollo/client");
exports.SchemaSetBulkAttributesOfAllEntitiesMutation = client_1.gql `
	mutation SchemaSetBulkAttributesOfAllEntitiesMutation ($id: ID!, $attributes: JSONObject!, $requestId: String) {
		schema {
			setBulkAttributesOfAllEntities(id: $id, attributes: $attributes, requestId: $requestId)
		}
	}
`;
