"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityChildrenCountQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityChildrenCountQuery = client_1.gql `
	query EntityChildrenCountQuery($id: ID!) {
		entity(id: $id) {
			id
			secondaryId

			childrenCounts {
				total
				places
				things
			}
		}
	}
`;
