"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityInstallCheckQuery = void 0;
const client_1 = require("@apollo/client");
exports.EntityInstallCheckQuery = client_1.gql `
	query EntityInstallCheckQuery($id: ID!) {
		canInstall(id: $id) {
			allowed
			id
		}
	}
`;
