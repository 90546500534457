"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSendPhoneVerificationCodeMutation = void 0;
const client_1 = require("@apollo/client");
exports.UserSendPhoneVerificationCodeMutation = client_1.gql `
	mutation UserSendPhoneVerificationCodeMutation($phone: String!) {
		user {
			sendPhoneVerificationCode(phone: $phone)
		}
	}
`;
