"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TraitQuery = void 0;
const client_1 = require("@apollo/client");
exports.TraitQuery = client_1.gql `
	query TraitQuery($id: ID!) {
		trait(id: $id) {
			id
			name
			key
			description
			status
			scope
			parentId

			avatar
			thumbnail: avatar(width: 5, height: 5)

			attributes {
				name
				key
				description
				schema
			}

			states {
				name
				key
				description
				schema
				communicationType
				sync {
					operation
					direction
					auto
				}
			}

			measurements {
				name
				key
				description
				schema
				sync {
					auto
				}
			}

			commands {
				name
				key
				description
				arguments {
					name
					key
					description
					schema
				}
			}

			ui {
				type
				targetType
				targetKey
				component
			}

			rules {
				id
				name
				description
				triggers {
					name
					options
				}
				condition {
					rules
					data
				}
				actions {
					name
					options
				}
			}

      reports {
        key
        name
        description
        type
        url
        layout
        icon {
          component
          name
          size
          source
          type
        }
      }
		}
	}
`;
