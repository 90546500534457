"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirmwareUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.FirmwareUpdateMutation = client_1.gql `
	mutation FirmwareUpdateMutation($id: ID!, $dto: FirmwareUpdateInput!) {
		firmware {
			update(id: $id, dto: $dto) {
				id
				code
				name
				description
				url
				latest
				schemaId
			}
		}
	}
`;
