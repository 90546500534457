"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityUpdateMutation = void 0;
const client_1 = require("@apollo/client");
exports.EntityUpdateMutation = client_1.gql `
	mutation EntityUpdateMutation($id: ID!, $dto: EntityInput!) {
		entity {
			update(id: $id, dto: $dto) {
				id
				secondaryId
				name
				avatar
				thumbnail: avatar(width: 5, height: 5)
				category
				deviceTypeHandle
				parentId

				uiResolutionKeys

				attributesMap
				stateMap

				location: stateMap(keys: ["geolocation.latitude", "geolocation.longitude"])

				schema {
					id
					handle
					mainState
					mainCommand

					localDeviceController {
						key
						options
					}
				}

				features {
					controls
					diagnostics
					quickCharts
					reports
					sensors
					settings
					wifi
				}
			}
		}
	}
`;
